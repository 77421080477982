import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const Come = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        checkIsMobile();
        window.addEventListener("resize", checkIsMobile);        
        return () => {
            window.removeEventListener("resize", checkIsMobile);
        };
    }, []);

    return (
        <motion.svg
            width='641px'
            height={isMobile ?'100px' : '120px' }
            viewBox={isMobile ? "0 0 641 150" : "0 0 641 110"}
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >
            <title>better-things-text</title>
            <defs>
                <rect id='path-1' x='0' y='0' width='641' height='122'></rect>
                <rect
                    id='path-3'
                    x='0'
                    y='0'
                    width='433.148591'
                    height='85'
                ></rect>
            </defs>
            <motion.g
                style={{ overflowY: "hidden" }}
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 6, duration: 1 }}
                id='Design'
                stroke='none'
                stroke-width='1'
                fill='none'
                fill-rule='evenodd'
            >
                <g id='Artboard-Copy-7'>
                    <g
                        id='better-things-text'
                    >
                        <g id='to-come'>
                            <mask id='mask-4' fill='white'>
                                <use xlinkHref='#path-1'></use>
                            </mask>
                            <g id='Rectangle'></g>
                            <path
                                d='M117.4,79.4h12v-7.7h-10.7c-3.4,0-6.1-1.8-6.1-6.3V31h17.5v-7.7h-17.5v-12.8h-8.6v12.8h-9.9v7.7h9.7v34.4c0,9.2,5.8,14,13.5,14ZM168.1,80.9c16.9,0,29.2-12.1,29.2-29.5s-12.2-29.5-29.2-29.5-29.1,12.2-29.1,29.5,12.2,29.5,29.1,29.5ZM148,51.4c0-12.3,8.4-21.6,20.1-21.6s20.1,9.2,20.1,21.6-8.4,21.6-20.1,21.6-20.1-8.9-20.1-21.6ZM266.1,80.9c11.3,0,21.8-5.2,26.4-18l-7.2-3.5c-3.3,9.1-11.1,13.4-19.2,13.4s-19.9-8.5-19.9-21.3,8.2-21.3,19.9-21.3,15.8,6.1,17.9,12.2l7.5-3.9c-4.5-11.3-14.8-16.5-25.4-16.5-16.5,0-28.9,12.5-28.9,29.5s12.1,29.5,28.9,29.5ZM330.5,80.9c16.9,0,29.1-12.1,29.1-29.5s-12.2-29.5-29.1-29.5-29.1,12.2-29.1,29.5,12.2,29.5,29.1,29.5ZM310.3,51.4c0-12.3,8.4-21.6,20.1-21.6s20.1,9.2,20.1,21.6-8.4,21.6-20.1,21.6-20.1-8.9-20.1-21.6ZM372.8,79.4h8.8v-33c0-9.7,6.1-16.4,14.4-16.4s14.5,5.5,14.5,14.6v34.8h8.8v-33c0-10.7,6.6-16.4,14.6-16.4s14.2,5.5,14.2,14.7v34.7h8.8v-36.4c0-11.6-6.9-21.1-19.7-21.1s-17.3,7.1-17.9,18.7c-1.8-14.1-10.6-18.7-19.6-18.7s-18.1,8.1-18.1,18.7v-17.3h-8.8v56.1ZM497,80.9c11.1,0,20-5.3,24.3-13.2l-6.6-4.2c-3.9,6.4-10,9.5-17.7,9.5-11,0-18-7.6-19.1-18.8h45.2c.1-1,.2-2.4.2-3.9,0-15.5-9.5-28.4-26.4-28.4s-27.8,11-27.8,28.9,11,30,27.9,30ZM478,46.4c1.5-9.4,8-16.8,18.9-16.8s17.8,7.5,17.8,16.8h-36.7ZM534.8,79.4h12v-13.5h-12v13.5Z'
                                fill='#FEFEFE'
                                fill-rule='nonzero'
                                mask='url(#mask-4)'
                            ></path>
                        </g>
                    </g>
                </g>
            </motion.g>
        </motion.svg>
    );
};

export default Come;
