import { motion } from "framer-motion";
const Icon = () => {
    const draw1 = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.25,
                    type: "ease",                    duration: 3,
                    bounce: 0,
                },
            },
        }),
    };
  return (
    <div className="icon">
    
<motion.svg initial='hidden'
      whileInView='visible'
      viewport={{amount: 'all', once: true}}
      width="102px" height="89px" viewBox="0 0 102 89" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage" transform="translate(-669.000000, -718.000000)" stroke="#F3BFB8" stroke-width="1.5">
            <motion.path d="M745,719 L695,719 L670,762.301304 L695,805.602472 L745,805.602472 L770,762.301304 L745,719 Z M732.499932,719.012273 L707.500068,719.012273 L695.000136,740.662857 L707.500068,762.313305 L732.499932,762.313305 L744.999864,740.662857 L732.499932,719.012273 Z M732.499932,740.662857 L707.500068,740.662857 L695.000136,762.313305 L707.500068,783.963889 L732.499932,783.963889 L744.999864,762.313305 L732.499932,740.662857 Z M732.499932,762.301577 L707.500068,762.301577 L695.000136,783.952025 L707.500068,805.602472 L732.499932,805.602472 L744.999864,783.952025 L732.499932,762.301577 Z M682.521478,740.662857 L757.478522,740.662857 L682.521478,740.662857 Z M682.521478,783.952025 L757.478522,783.952025 L682.521478,783.952025 Z M707.500068,740.662857 L732.499932,783.963889 L707.500068,740.662857 Z M732.499932,740.662857 L707.500068,783.963889 L732.499932,740.662857 Z" id="Stroke-1" custom={1}
        variants={draw1}></motion.path>
        </g>
    </g>
</motion.svg>
    </div>
  );
};

export default Icon;
